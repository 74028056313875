import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from './style'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';


// import image from '../../../../assets/image/services/solution-development/1.gif'


export default function ContentSectionOne() {

  return (
    <Content>
      {/* <Content.SectionShape>
        <Img src="../../../assets/image/project-management/l2-content-1-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
      </Content.SectionShape> */}
      <Container>
        <Content.Inner>
          <Row className="align-items-center justify-content-center justify-content-lg-start">
            <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
              <Content.TextBlock>
                <Content.Title as="h2"> <SuperTag value="Solution <br className='d-none d-lg-block' /> 
                
                
                 "

                />
                  <div style={{ color: "#8a55df" }} >
                    <ReactTypingEffect
                      text={[" Development/ POC", " Development/ POC"]}
                      className="highlight-text d-lg-block "
                      speed="150"
                      eraseSpeed="100"
                      typingDelay="400"
                      eraseDelay="800"
                      cursorClassName="typed-cursor">

                    </ReactTypingEffect>
                  </div>



                </Content.Title>

                <Content.Text> <SuperTag value="Each and every member in Axcer family is tightly knit with cutting edge
                 technologies which make an eager to enjoy PoC including Research and Development.  We have already delivered many solutions
                  as Minimum Viable Products in order to visualize customers’ imaginations. Blockchain application development is another passion
                   in Axcers’ diverse team while our talented UI/UX team is ensuring a great professional experience for system users." /> </Content.Text>

                {/* <Content.Button to="/contact"> Contact Us
                </Content.Button> */}
                <Content.ButtonTwo to={'/contact'}>Get Quotation</Content.ButtonTwo>

              </Content.TextBlock>
            </Col>
            <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
              <Content.Image>
                <Img className="w-100" src='../../../../assets/image/services/solution-development/9.png' alt="content" layout="fullWidth" placeholder="blurred" />
                {/* <Content.ContentShape>
                  <Img className="w-100" src="../../../assets/image/project-management/l2-content-shape-2.png" alt="content" layout="fullWidth" placeholder="blurred" />
                </Content.ContentShape> */}
              </Content.Image>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}